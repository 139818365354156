import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import { GlobalContext } from '../../../context/GlobalContext'
import ListingsProvider, { initialFilter } from '../../../components/ListingsProvider/ListingsProvider'
import Navbar from '../../../components/Navbar/Navbar'
import { toast } from 'react-toastify'

import NewEqipoImages from './NewEqipoImages'
import NewEqipoForm, { type FilledEqipoInfo } from './NewEqipoForm'
import NewEqipoReview from './NewEqipoReview'
import listingsService from '../../../services/listingsService'
import eqipoService from '../../../services/eqipoService'
import { type Option } from '../../../models/Components'
import { type Image } from '../../../components/UploadImage/UploadImage'
import { translateCondition } from '../Eqipo'
import ToastMessage from '../../../components/ToastMessage/ToastMessage'
import FormPagination from '../../../components/FormPagination/FormPagination'
import ModalFinish from './modalFinish/ModalFinish'
import { type SportItem } from '../../../services/sportService'

import './NewEqipo.scss'
import { type ListingBrand, type ListingSize } from '../../../models/Listings'
import paymentsService from '../../../services/paymentsService'

export interface ListingPhotos {
  cover: string
  photos: string[]
}

export interface WallpaperImage {
  hasUploaded: boolean
  src?: string
}

export interface EqipoInfo {
  title?: string
  story?: string
  sports?: SportItem[]
  itemCondition?: Option
  price?: string
  priceWithMask?: string
  termOfAgreement?: string
  hasInvoice?: boolean
  brand?: ListingBrand
  size?: ListingSize
  itemStatus?: string
}

const NewEqipo = () => {
  const initialWallpaper: WallpaperImage = { hasUploaded: false }

  const initialImages: Image[] = [
    { id: 1, hasUploaded: false },
    { id: 2, hasUploaded: false },
    { id: 3, hasUploaded: false },
    { id: 4, hasUploaded: false },
    { id: 5, hasUploaded: false },
    { id: 6, hasUploaded: false }
  ]

  const [currentStep, setCurrentStep] = useState<number>(1)
  const [listingPhotos, setListingPhotos] = useState<ListingPhotos>()
  const [listingData, setListingData] = useState<FilledEqipoInfo>()
  const [images, setImages] = useState(initialImages)
  const [isLoading, setIsLoading] = useState(false)
  const [wallpaper, setWallpaper] = useState(initialWallpaper)
  const [eqipoInfo, setEqipoInfo] = useState<EqipoInfo>({})
  const [listingId, setListingId] = useState('')
  const [idModal, setIdModal] = useState('')
  const history = useHistory()
  const location = useLocation()
  const { userProfile } = useContext(GlobalContext)

  const handleSaveImages = (galleryImages: string[], wallpaperImage: string) => {
    setListingPhotos({ photos: galleryImages, cover: wallpaperImage })
    setCurrentStep(2)
  }

  const handleSubmitForm = async (listingForm: FilledEqipoInfo) => {
    if (!listingPhotos) throw Error('Imagens do equipo são obrigatórias.')
    setListingData(listingForm)
    setCurrentStep(3)
  }

  async function finishForm (listingForm: FilledEqipoInfo) {
    setIsLoading(true)
    if (!listingPhotos) throw Error('Imagens do equipo são obrigatórias.')
    const listingPayload = { ...listingForm, ...listingPhotos }
    if (listingId) {
      await listingsService.editListing(listingId, listingPayload)
      setIdModal(listingId)
    } else {
      const { id } = await listingsService.postListing(listingPayload)
      await listingsService.sendToReview(id)
      setIdModal(id)
    }
    setIsLoading(false)
    setCurrentStep(4)
  }

  useEffect(
    () => {
      async function loadEqipo () {
        try {
          if (location.pathname.includes('editar') && !listingId) {
            const id = location.pathname.split('/')[2]
            const listing = await eqipoService.getUserEqipo({ id })
            setListingId(id)
            setWallpaper({ hasUploaded: true, src: listing.cover })
            listing.photos.forEach((photo: string, idx: number) => {
              images[idx] = {
                id: idx + 1, hasUploaded: true, src: photo
              }
            })
            setImages([...images])
            setEqipoInfo({
              title: listing.title,
              story: listing.story,
              sports: listing.sports,
              itemCondition: { value: listing.itemCondition, label: translateCondition(listing.itemCondition) },
              price: listing.price.toString(),
              priceWithMask: listing.price.toString(),
              hasInvoice: listing.hasInvoice,
              brand: listing.brand,
              size: listing.size,
              itemStatus: listing.itemStatus
            })
          }
        } catch (error) {
          const { isWarn, message } = error as any
          isWarn ? toast.warn(<ToastMessage label={message}/>) : toast.error(<ToastMessage />)
        }
      }

      void loadEqipo()
    },
    [images, listingId, location.pathname]
  )

  useEffect(() => {
    const verifyAccount = async () => {
      try {
        await paymentsService.getPaymentAccount()
      } catch (error) {
        try {
          await paymentsService.createPaymentAccount()
        } catch (error) {
          // toast.warn(<ToastMessage label="Complete seu cadastro para vender um eqipo"/>)
          history.replace(`/atleta/${userProfile?.username}/editar/checkout?nextScreen=newEqipo`)
        }
      }
    }

    void verifyAccount()
  },
  [history, userProfile])

  return (
    <div className="new-eqipo">
      <ListingsProvider filter={initialFilter}>
        <Navbar/>
      </ListingsProvider>
      <div style={{ padding: '24px 20px' }}>
        <FormPagination
          steps={[{ id: 1, label: 'Fotos' }, { id: 2, label: 'Informações' }, { id: 3, label: 'Confirmar' }]}
          activateStep={currentStep}
          setCurrentStep={({ id }) => {
            setCurrentStep(id)
          }}
        />
        <div style={{ paddingTop: '20px' }}/>

        {currentStep === 1 &&
          <NewEqipoImages
            onSaveImages={handleSaveImages}
            images={images}
            setImages={setImages}
            wallpaper={wallpaper}
            setWallpaper={setWallpaper}
          />}

        {currentStep === 2 &&
          <NewEqipoForm
            onSubmitForm={handleSubmitForm}
            eqipoInfo={eqipoInfo}
            setEqipoInfo={setEqipoInfo}
          />}
        {currentStep === 3 &&
          <NewEqipoReview
            onSubmitForm={async () => {
              if (listingData) {
                await finishForm(listingData)
              }
            }}
            backStage={() => {
              setCurrentStep(1)
            }}
            formData={listingData}
            formPhotos={listingPhotos}
            isLoading={isLoading}
          />}
        {currentStep === 4 && eqipoInfo.itemStatus === 'enabled' && (
          <Redirect to={`/eqipo/${listingId}/`}/>
        )}
        {currentStep === 4 && <ModalFinish idModal={idModal}/>}
      </div>
    </div>
  )
}

export default NewEqipo
